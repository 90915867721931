'use strict';

import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactDragList from '../DraggableList'

import { MdSwapVert } from "react-icons/md";

import { observer } from 'mobx-react';
import { Element, scroller, animateScroll } from 'react-scroll';
import { useMst } from "../../../models";

const Step = observer(({ question }) => {
  const { user, step } = useMst();
  const [dataSource, setDataSource] = useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const container = useRef();

  useEffect(() => {
    setDataSource(JSON.parse(JSON.stringify(question.options.toJSON())))

    animateScroll.scrollToTop({
      duration: 50,
      delay: 0,
      smooth: true,
      offset: 0,
    })

    // if (step.nowQuestionNumber === question.order) {
    //   scroller.scrollTo('scroll-' + (question.order + 1), {
    //     duration: 50,
    //     delay: 1000,
    //     smooth: true,
    //     offset: -50, // Scrolls to element + 50 pixels down the page
    //   })
    // }
    
  }, [])

  const onUpdate = (e, updated) => {
    if (step.nowQuestionNumber < question.order) step.setNowQuestionNumber(question.order)
    
    if (e.oldIndex > e.newIndex) {
      for (let i = e.newIndex; i <= e.oldIndex; i++) {
        e.to.children[i].children[2].innerHTML = (i + 1);
      }
    } else {
      for (let i = e.oldIndex; i <= e.newIndex; i++) {
        e.to.children[i].children[2].innerHTML = (i + 1);
      }
    }

    const a = {
      type: question.type,
      answerType: question.answerType,
      title: question.title,
      userId: user.id,
      questionsId: question.id,
      answer: JSON.parse(JSON.stringify(updated)),
      options: JSON.parse(JSON.stringify(question.options.toJSON())),
    }

    step.setAnswer(question.order-1, a)

    // // scroller.scrollTo('scroll-' + (question.order + 1), {
    // //   duration: 50,
    // //   delay: 0,
    // //   smooth: true,
    // //   offset: -50,
    // // })
    // forceUpdate()
  }

  const q = {
    '다른 사람과 대화할 때 나는,': 'When talking to others, I',
    '보통 즉흥적으로 여러가지 생각들을 이야기하는 편이다': 'usually refer to various ideas spontaneously.',
    '말하기 보다는 주로 이야기를 들어주는 편이다': 'first listen to the thoughts and opinions of others before giving an opinion.',
    '단도직입적으로 나의 생각이나 의견을 이야기하는 편이다': 'like to express my opinions directly.',
    '객관적인 정보들을 중심으로 이야기하는 편이다': 'tend to speak calmly and objectively.',
    '중요한 결정을 내릴 때 나는,': 'When making important decisions, I',
    '일(공부)에 집중이 잘 되지 않을 때 나는,': 'When dealing with others, I',
    '내가 비즈니스에서(학교생활)에서 만족을 느낄 때는,': 'I am satisfied',
    '내가 다른 사람들에게 주고 싶은 이미지는,': 'The image I want to give to others is,',
    '내가 일(공부)를 하고 싶은 장소는,': 'I can work comfortably',
    '나는 주변사람들로부터 자주 듣는 충고는,': 'I feel joy when I',
    '내가 좋아하는 사람은,': 'I like',
    '어려움에 처했을 때 나는,': 'When solving a problem situation, I',
    '관련 정보들을 수집하고 분석하여 신중하게 결론을 내린다': 'reach to conclusion carefully by comparing and analyzing facts, principles, and alternatives.',
    '가장 현실적이고 실행가능한 방안이 무엇인지 생각한다': 'suggest practical and realistic ideas during meetings.',
    '관련된 모든 사람들이 win-win(윈-윈)할 수 있는 방법이 무엇인지 생각한다': 'prioritize the interests of the whole over individual interests.',
    '그 결정이 미래에 어떠한 변화를 가지고 올지, 얼마나 가치가 있을지 생각한다': 'consider the future consequences of current practices.',
    '에너지를 얻기 위해 잠시 딴 짓을 한다': 'ask questions about their thoughts, visions, and values.',
    '사람들과 커피나 음료를 마시며 재충전을 한다': 'try to empathize with their opinions or ideas.',
    '다른 해야 할 일을 찾아본다': 'reach out to people with confidence and share my opinions or thoughts.',
    '인내력을 가지고 끝까지 집중하려고 노력한다': 'keep a distance and treat yourself formally until I feel like she or he is mine.',
    '준비할 수 있는 충분한 시간이 있고, 계획대로 일이 순조롭게 진행될 때이다': 'when I can have enough time to prepare or execute.',
    '결정권한이 주어지고, 주도적으로 일을 성취해 나갈 때이다': 'when I can have a lot of control over the process and the outcome.',
    '모두 함께 하나의 공통된 목표를 향해 협력해 나갈 때이다': 'when I can share warmth and friendship.',
    '새롭고 흥미로운 일을 진행할 때이다': 'when I can make a new change or plan for it in my daily life.',
    '재치 있고 아이디어가 넘치는 사람이란 말을 듣고 싶다': 'amazing and innovative.',
    '편안하고 마음이 따뜻한 사람으로 느껴졌으면 좋겠다': 'good and kind.',
    '끊임없이 도전하고 능력 있는 사람으로 보여 졌으면 좋겠다': 'energetic and courageous.',
    '꼼꼼하고 논리적인 사람으로 인정받았으면 좋겠다': 'analytical and logical.',
    '다른 사람들과 공간이 분리된 조용한 공간이었으면 좋겠다': 'where familiar and regular daily routines follow.',
    '어디든 상관없이 일(공부)에 집중할 할 수 있는 곳이면 좋겠다': 'where maximum authority is given.',
    '친한 사람들과 함께 할 수 있는 아늑한 공간이었으면 좋겠다': 'where all focus is on the needs of team members and a culture of sharing rewards is fostered.',
    '자유롭고 편하게 일(공부) 할 수 있는 곳이면 좋겠다': 'where a culture of openness to new ideas is established.',
    '너무 즉흥적으로 결정하지 말고 신중하게 생각해보면 좋겠다는 것이다': 'experience new things in various fields.',
    '너무 주변 사람들의 눈치를 보지 않았으면 좋겠다는 것이다': 'give hope and vitality to people around me.',
    '너무 조급하게 서두르지 않았으면 좋겠다는 것이다': 'take initiative and do what I want to do.',
    '가끔은 일상에서 벗어나 새로운 시도를 해보면 좋겠다는 것이다': 'plan anything ahead and things go smoothly.',
    '매사에 진지하고 성실하며 예의 바른 사람이다': 'a person who is serious, sincere, and acts right.',
    '알아서 자기 할 일을 잘 해내는 사람이다': 'a person who acts voluntarily.',
    '상대방을 입장에서 생각할 줄 알고, 남을 배려하는 사람이다': 'a person who is considerate and help others.',
    '유쾌하고 흥미로운 이야기로 즐거움을 주는 사람이다': 'a person who gives pleasure with interesting and interesting stories.',
    '즉흥적으로 떠오르는 방법으로 문제를 해결하는 편이다': 'find new and innovative ways to solve problems.',
    '다른 사람들과 충분한 상의 후 문제를 해결하는 편이다': 'reconcile conflicting interests.',
    '원인을 파악한 후 과감하고 신속하게 문제를 해결하는 편이다': 'clarify ambiguous topics.',
    '충분하게 검토한 후 문제를 차근차근 풀어가는 편이다': 'take logical approach.',
  }

  const convert = (data) => {
    return q[data]
  }

  return (
    <>
      <div className="step-content-field">
        <div className="plan-area">
          <Element name={'scroll-' + question.order}>
            <div className="budget-area" >
              <p>{parseInt(question.order) < 10 ? '0' + question.order : question.order}.
               {step.lang === 'ko' ? question.question : convert(question.question)}
              </p>
              <div className={question.answerType + "-select-option"}>
                <ReactDragList
                  rowClassName="bg-white"
                  dragClass="active"
                  ghostClass="active"
                  animation={200}
                  handles={true}
                  dataSource={dataSource}
                  onUpdate={onUpdate}
                  ref={container}
                  row={( record, index) => {
                    return (
                      <>
                        <div className="move"><MdSwapVert /></div>
                        <div className="option-number" >{index + 1}</div>
                        <div className="option-text vcontainer"><div className="text">{step.lang === 'ko' ? record.text : convert(record.text)}</div></div>
                      </>
                    )
                  }}
                />
              </div>
            </div>
          </Element>
        </div>
      </div>
    </>
  );
});

export default Step;