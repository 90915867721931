
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../assets/img/code.jpg';
import { getItem, setItem } from '../lib/localstorage';
import { useMst } from "../models";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
 
const Code = (props) => {
  const [codeResult, setCodeResult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { user, step } = useMst();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!step.testStep || step.testStep < 1 || getItem('complete')) navigate('/');
    if (step.testStep > step.openStep || !step.openStep) step.setOpenStep(step.testStep);

    setTimeout(() => {
      const hId = location.search.replace('?hId=', '')
      sendEmail(hId)
      setIsLoading(false)
    }, 10000)
  }, [])

  const sendEmail = async (hId) => {
    if (!step.testStep || step.testStep < 1) {
      navigate('/');
      return
    }
    if (step.testStep > step.openStep || !step.openStep) {
      step.setOpenStep(step.testStep);
      return
    }

    step.setLoading(true)
    console.log({
      userId: user.id,
      historyId: hId,
      type: step.type,
      premium: step.premium
    })

    const response = await fetch('https://api.icru.co.kr/api/v1/send-email', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        historyId: hId,
        type: step.type,
        premium: step.reportType
      }),
    })

    const result = await response.json()

    if (result.result && result.code === 200) {
        toast.success('ICRU 디브리퍼에게 이메일 발송 하였습니다.')
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
    step.setLoading(false)
    setItem('complete', true)
  }

  return (
    <div className="wrapper" style={{ backgroundImage: `url(${background})`, height: '100vh' }}>
      {isLoading &&
        <div className="loading">
          <div className="code-title">
            <div className="thank-txt text-center thank-icon">
              <div>
                <img src={require("../assets/img/spaceman.png")} alt="" width="300px" />
              </div>
              <div>
                <img src={require("../assets/img/loading.gif")} alt="" width="200px" />
              </div>
              <h4>디브리퍼에게 메일 발송 중입니다.</h4>
            </div>
          </div>
        </div>}
      {!isLoading &&
        <div className='home'>
          <div className="wrapper">
            <div className='logo'>
              <h1>ICRU</h1>
            </div>
            <div className="start-button">
              <div className='button'>
                <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
                  <div className="step-progress">
                    <div className="progress" style={{ border: 'none' }}>
                      <div className="progress-bar" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                </div>
                <div style={{
                  fontFamily: 'Pretendard-Variable-Black',
                  width: '550px',
                  marginLeft: '-125px',
                  padding: '20px',
                  backgroundColor: 'white',
                  marginTop: '20px',
                  borderRadius: '20px'
                }}>
                  <h4 style={{ padding: '10px' }}>진단이 완료되었습니다.</h4>
                  <p style={{ fontSize: '1.1rem' }}>감사합니다. ICRU디브리퍼에게 결과가 전달되었습니다.<br />
                    ICRU디브리퍼가 이 결과를 토대로 여러분의 삶을 함께 여정 해 주실겁니다.<br />
                    곧 뵙겠습니다!</p>
                </div>
              </div>
            </div>
            <div className='bottom-logo'>
              ⓒ<img src={require("../assets/img/logo2.png")} alt="" />
            </div>
          </div>
        </div>}
    </div>
  )
};

export default Code;