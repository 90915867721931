
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../assets/img/code.jpg';
import { getItem } from '../lib/localstorage';
import { useMst } from "../models";
import { observer } from 'mobx-react';

const Code = observer(() => {
  const [codeResult, setCodeResult] = useState();
  const [codeValue, setCodeValue] = useState('');
  const { user, step, answer } = useMst();
  const navigate = useNavigate();


  const useCode = async (e) => {
    const response = await fetch('https://api.icru.co.kr/api/v1/code/use-code', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        code: e.currentTarget.value,
      }),
    })

    const result = await response.json()
    console.log(result)

    if (result.result && result.code === 200) {
      setCodeResult(result.data.result);

      if (result.data.result === 3) {
        setCodeValue('');
        user.reset();
        answer.reset()
        step.reset()
        step.setReportType(result.data.code.reportType)
        step.setTestStep(1);
        step.setData({
          code: result.data.code.code,
          ownerId: result.data.code.ownerId,
          groupName: result.data.groupName,
        })
        navigate('/test');
      }
    }
  }

  return (
    <div className="wrapper" style={{ backgroundImage: `url(${background})`, height: '100vh' }}>
      <div className="code">
      <div style={{color: step.lang === 'en' ? 'white' : 'red', cursor: 'pointer'}} onClick={() => {
          step.setLang('ko')
          console.log('korean')
        }}>
          <img src={require("../assets/img/korea.png")} alt="" width="30px" /> 한국어
        </div>
        <br/>
        <div style={{color: step.lang === 'ko' ? 'white' : 'red', cursor: 'pointer'}} onClick={() => {
          step.setLang('en') 
          console.log('english')
        }}>
          <img src={require("../assets/img/usa.png")} alt="" width="30px" /> English
        </div>
        <br/>
        <div className="code-title">
          <input
            type="text"
            name="code"
            className="form-control required"
            maxLength="6"
            placeholder={step.lang === 'ko' ? '코드번호입력' : 'code'}
            required
            style={{ width: '200px', height: '45px', borderRadius: '5rem', fontSize: '1.2rem', letterSpacing: '0.5rem', fontWeight: 500, marginBottom: '20px' }}
            onInput={(e) => {
              setCodeValue(e.currentTarget.value)
              if (e.currentTarget.value.length === 6) {
                useCode(e)
              } else {
                setCodeResult(4);
              }
            }}
          />
          {codeResult === 1 && <h4>* 등록된 코드번호가 없습니다.</h4>}
          {codeResult === 2 && <h4>* 이미 사용된 코드번호 입니다.</h4>}
          {codeResult === 3 && <h4>* 코드번호를 사용합니다.</h4>}

        </div>
      </div>
    </div>
  )
});

export default Code;