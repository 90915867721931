
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMst } from "../models"

const Home = () => {
	const { step } = useMst();

	useEffect(() => {
		step.setLoading(false);
	}, [])

	return (
		<div className='home'>
			<div className="wrapper">
				<div className='logo'>
					<h1>ICRU</h1>
				</div>
				<div className="start-button">
					<div className='button'>
						<Link to="/code">
							<span className="js-btn-next" title="NEXT">
								기질진단 Start →
							</span>
						</Link>
					</div>
				</div>
				<div className='bottom-logo'>
					ⓒ<img src={require("../assets/img/logo2.png")} alt="" />
				</div>
			</div>
		</div>
	)
};

export default Home;

