import React from "react";
import { observer } from 'mobx-react';
import { useMst } from "../../../models";
import { useEffect } from "react";

const Step = observer(() => {
  const { user, step } = useMst();

  const updateUser = (data) => {
    user.setData({ ...user, ...data });
  }

  useEffect(() => {
    if (step.groupName) {
      user.setData({ agency: step.groupName })
    }
  }, [])

  return (
    <>
      {step.lang === 'ko' && 
      <>
      <p className="step-no">STEP 03</p>
      <h2>저희에게 소속과 직급을 알려주세요.</h2>
      <p>정확히 기입 해 주셔야 진단 결과를 받으실 수 있어요.</p>
      <div className="notice-wrapper">
        <div className="notice-content">
          <h4 className="notice">① 직장인이시라면 [회사명/직급]</h4>
          <h4 className="notice">② 대학생이시라면 [대학생/학년]</h4>
          <h4 className="notice">③ 프리랜서 혹은 1인기업이시라면 [프리랜서/프리랜서]</h4>
          <h4 className="notice">④ 무직이시라면 [기타/기타]</h4>
        </div>
      </div>
      <div className="step-content-area">
        <div className="form-inner-area">
          <input type="text" name="agency" className="form-control " placeholder="소속 *" required
            value={user.agency}
            onInput={(e) => { updateUser({ agency: e.currentTarget.value }) }}
            disabled={!!step.groupName}
          />
          <input type="text" name="rank" className="form-control " placeholder="직급 *" required
            value={user.rank}
            onInput={(e) => { updateUser({ rank: e.currentTarget.value }) }}
          />
          {step.groupName &&
            <div>
              <div className="row">
                <div className="col-md-6"
                  onClick={() => {
                    updateUser({ isLeader: true })
                  }}
                >
                  <label className={"gender-step-box-content bg-white text-center relative-position " + (user.isLeader ? 'active' : '')} style={{ float: 'right' }}>
                    <span className="step-box-text">리더</span>
                    <span className="service-check-option">
                      <span>
                        <input
                          type="radio"
                          name="gender"
                          defaultValue="M"
                          defaultChecked
                        />
                      </span>
                    </span>
                  </label>
                </div>
                <div className="col-md-6"
                  onClick={() => {
                    updateUser({ isLeader: false })
                  }}
                >
                  <label className={"gender-step-box-content bg-white text-center relative-position " + (!user.isLeader ? 'active' : '')}>
                    <span className="step-box-text">팀원</span>
                    <span className="service-check-option">
                      <span>
                        <input
                          type="radio"
                          name="gender"
                          defaultValue="F"
                        />
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>}
        </div>
      </div>
      </>}
      {step.lang === 'en' && <>
        <p className="step-no"></p>
        <h2>Start!</h2>
      </>}
    </>
  );
});

export default Step;