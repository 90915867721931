'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _sortablejs = require('sortablejs');

var _sortablejs2 = _interopRequireDefault(_sortablejs);

var isUpdated = false;

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj["default"] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var DraggableList = function (_React$Component) {
	(0, _inherits3["default"])(DraggableList, _React$Component);

	function DraggableList() {
		(0, _classCallCheck3["default"])(this, DraggableList);

		var _this = (0, _possibleConstructorReturn3["default"])(this, _React$Component.apply(this, arguments));

		_this._current = [];
		_this._sortableGroupDecorator = function (componentBackingInstance) {
			// check if backing instance not null
			if (componentBackingInstance) {
				var _this$props = _this.props,
					animation = _this$props.animation,
					_onUpdate = _this$props.onUpdate,
					prefixCls = _this$props.prefixCls,
					ghostClass = _this$props.ghostClass,
					chosenClass = _this$props.chosenClass,
					dragClass = _this$props.dragClass,
					disabled = _this$props.disabled;
				// const ghostClass = ;

				var options = {
					disabled: disabled,
					animation: animation,
					draggable: '.' + prefixCls + '-draggableRow',
					// group: "shared",
					ghostClass: ghostClass || prefixCls + '-ghost',
					chosenClass: chosenClass || prefixCls + '-chosen',
					dragClass: dragClass || prefixCls + '-drag',
					onUpdate: function onUpdate(evt) {
						isUpdated = true;
						var newIndex = evt.newIndex,
							oldIndex = evt.oldIndex;

						var updated = _this._current;
						var rowData = updated.splice(oldIndex, 1);
						updated.splice(newIndex, 0, rowData[0]);
						_this._current = updated;
						_onUpdate && _onUpdate(evt, updated); // tslint:disable-line
					}
				};
				_sortablejs2["default"].create(componentBackingInstance, options);
			}
		};
		return _this;
	}

	DraggableList.prototype.render = function render() {
		var _props = this.props,
			style = _props.style,
			className = _props.className,
			rowKey = _props.rowKey,
			rowClassName = _props.rowClassName,
			prefixCls = _props.prefixCls,
			dataSource = _props.dataSource,
			row = _props.row,
			handles = _props.handles,
			disabled = _props.disabled;
		
		if (!isUpdated) {
			this._current = dataSource;
		}

		return React.createElement("div", {
			className: (0, _classnames2["default"])(prefixCls, className),
			style: style
		}, React.createElement("div", { ref: this._sortableGroupDecorator }, dataSource.map(function (record, index) {
			return React.createElement("div", {
				key: rowKey ? record[rowKey] : 'drag-' + index,
				className: (0, _classnames2["default"])(prefixCls + '-draggableRow', rowClassName),
				style: {
					cursor: disabled ? 'default' : 'move'
				}
			},
				!disabled
				&& handles
				&& React.createElement("span", {
					className: prefixCls + '-handles'
				}, '\u2630'),
				row(record, index));
		})));
	};

	return DraggableList;
}(React.Component);

exports["default"] = DraggableList;

DraggableList.defaultProps = {
	disabled: false,
	handles: true,
	animation: 150,
	prefixCls: 'rc-draggable-list'
};
module.exports = exports['default'];