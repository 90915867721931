import React, { createRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Step1 from './components/step/step-1';
import Step2 from './components/step/step-2';
import Step3 from './components/step/step-3';
import Step4 from './components/step/step-4';
import Step5 from './components/step/step-5';
import Step6 from './components/step/step-6';

import { useMst } from "../models";
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getItem, setItem } from '../lib/localstorage';
import { toast } from 'react-toastify';
import { isEmail } from '../lib/checker';
import { scroller } from 'react-scroll';

const VersionOne = observer(() => {

  const industryOptions1 = [
    '의료·보건', '마케팅·광고·홍보', '서비스', '특수계층·공공', '교육', '미디어·문화·스포츠', 'IT·인터넷', '디자인',
    '연구개발·엔지니어', '경영·기획', '전문직', '무역·유통', '영업·판매·고객상담', '건설', '생산·제조', '기타'
  ]

  const industryOptions2Array = [
    ['간호사·간호조무사', '의료기사', '사무·원무·코디', '수의사·수의간호', '약사', '의사·한의사', '의료직 기타'],
    ['마케팅·브랜드', '홍보·사보', '광고·광고기획', '리서치·조사·분석·통계', '이벤트·행사', '전시·컨벤션'],
    ['레저·스포츠', '여행·호텔·항공·교통', '외식·식음료·요리', '경비·보안·경호', '건물·시설관리', '뷰티·미용·애완', '사회복지·상담·자원봉사'],
    ['공무원·공사', '장교·군인·부사관'],
    ['입시·보습 학원강사', '외국어·IT·전문직업 강사', '학습지, 방문교사·과외', '유치원·어린이집', '교사·교수·강사', '교직원'],
    ['연출·PD·감독', '시나리오·방송 작가', '방송진행·아나운서·리포터', '촬영·편집', '기자', '공연·전시·무대', '인쇄·출판·편집',
      '스포츠·운동선수', '문화·예술·창작', '음악·음향', '영화·배급', '크리에이터', '엔터테인먼트·연예·모델·매니저'],
    ['웹기획·웹마케팅·IT PM', '콘텐츠·사이트·커뮤니티 운영', '웹디자인', '웹프로그래머', '응용프로그래머', '시스템프로그래머',
      '서버·보안·네트워크·DB', '시스템분석·설계·시스템엔지니어', '게임 기획·개발·운영', 'HTML·코딩·퍼블리셔', 'AI·빅데이터'],
    ['시각·광고디자인', '출판·편집디자인', '산업·제품디자인', '패션·의류디자인', '캐릭터디자인', 'CG그래픽디자인', '공간·전시디자인'],
    ['기계·자동차·조선·철강', '디스플레이·반도체', '화학·에너지', '제약·식품', '전자·제어·전기', '기계설계·CAD·CAM', '정보통신·네트워크'],
    ['경영·전략·기획', '총무·법무·사무', '경리·세무·회계', '재무·자금·IR', '비서·수행·안내', '인사·교육'],
    ['특허·법무', '통역·번역', '금융·자산관리', '경영분석·컨설팅', '회계·세무사(공인)', '헤드헌터·노무·직업상담', '사회 / 인문 등의 연구직·공학개발직', '문화·사서', '감정·경매·변리'],
    ['무역·해외영업', '무매·자재·재고', '유통·물류', '배송·운송', '상품기획'],
    ['일반영업', '금융·보험영업', '기술영업(IT / 솔루션)', '영업관리·영업지원', '판매·매장관리', '아웃바운드TM', '인바운드TM·CS·고객센터', '고객관리', '교육상담·학원관리', '부동산·창업'],
    ['건축설계·인테리어', '토목·측량·조경·환경', '기계·전기·소방·설비', '시공·감리·공무'],
    ['생산관리·품질관리', '생산·제조·조립·설비', '포장·검사·가공', '설치·정비·AS', '농축산·어업·임엄'],
    ['주부', '중 / 고등학생', '정년퇴직'],
  ];

  const { user, step } = useMst()
  const [industryOptions2, setIndustryOptions2] = useState([])
  // const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (step.testStep === 8) navigate('/loading');
    if (!step.testStep || step.testStep < 1) navigate('/');
    if (step.testStep > step.openStep || !step.openStep) step.setOpenStep(step.testStep);

    if (user.industryId > -1) setIndustryOptions2(industryOptions2Array[user.industryId]);
  }, [step.testStep])

  const selectIndustry1 = (i) => {
    user.setIndustryId(i);
    setIndustryOptions2(industryOptions2Array[i])
    step.setTestStep(step.testStep + 1)
  }

  const selectIndustry2 = (i) => {
    user.setJobGroupId(i);
    step.setTestStep(step.testStep + 1)
  }

  const onNext = async () => {
    if (step.testStep === 1) {
      if (!user.name) {
        toast.warn('이름은 필수 입니다.');
        return
      }
      if (!user.email) {
        toast.warn('이메일은 필수 입니다.');
        return
      }
      if (!isEmail(user.email)) {
        toast.warn('이메일 형식이 아닙니다.');
        return
      }
      if (!user.birthYear) {
        if (step.lang === 'ko') {
          toast.warn('출생년도는 필수 입니다.');
          return
        }
        user.setData({birthYear: 2023})
      }
      if (!user.gender) {
        toast.warn('성별은 필수 입니다.');
        return
      }

      console.log(user.me)
      if (!user.agree) {
        toast.warn(step.lang === 'ko' ? '이용약관 및 개인정보처리 방침에 동의 하셔야 진단을 받으실 수 있습니다.' :
        'You must agree to the Terms of Use and Privacy Policy to receive a diagnosis.');
        return
      }
    }

    if (step.testStep === 2) {
      if (user.industryId < 0) {
        toast.warn('직업군 선택은 필수 입니다.');
        return
      }
    }

    if (step.testStep === 3) {
      if (user.jobGroupId < 0) {
        toast.warn('직군(소분류) 선택은 필수 입니다.');
        return
      }
    }

    if (step.testStep === 4) {
      if (!user.agency && step.lang === 'ko') {
        toast.warn('소속은 필수 입니다.');
        return
      }

      if (!user.rank && step.lang === 'ko') {
        toast.warn('직급은 필수 입니다.');
        return
      }

      user.setData({ ...user, createdAt: new Date(), updatedAt: new Date() });

      const response = await fetch('https://api.icru.co.kr/api/v1/user/register', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...user.me, code: step.getCode }), 
      })

      const result = await response.json()

      if (result.result && result.code === 200) {
        user.setData(result.data.user)
        step.setData({type: result.data.code.user.type})
        setItem('access-token', response.headers.get('access-token'))
        setItem('refresh-token', response.headers.get('access-token'))
      } else {
        toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
        return
      }

      // if (!step.questions) {
        const questionResponse = await fetch('https://api.icru.co.kr/api/v1/questions/icru', {
          headers: {
            'client-id': 'kr.co.icru',
            'user-id': user.id,
            'authorization': 'Bearer ' + getItem('access-token')
          }
        })
        const questionResult = await questionResponse.json()
        if (questionResult.result && questionResult.code === 200) {
          step.setQuestions(questionResult.data)

          let answerTemp = []
          for (let i = 0; i < questionResult.data.length; i++) {
            answerTemp.push({
              type: questionResult.data[i].type,
              title: questionResult.data[i].title,
              answerType: questionResult.data[i].answerType,
              userId: user.id,
              questionsId: i < 9 ? questionResult.data[i].id : 0,
              answer: questionResult.data[i].options,
              options: questionResult.data[i].type === 'rank' ? questionResult.data[i].options : [questionResult.data[i].options[0]],
            })
          }
          step.setAnswerInit(answerTemp)

        } else {
          toast.error('질문지 불러오기 실패!! 처움부터 다시 시도!')
          return
        }
      // }

      const answerResponse = await fetch('https://api.icru.co.kr/api/v1/answer/' + user.id, {
        headers: {
          'client-id': 'kr.co.icru',
          'user-id': user.id,
          'authorization': 'Bearer ' + getItem('access-token')
        }
      })

      const answerResult = await answerResponse.json()

      if (answerResult.result && answerResult.code === 200) {
        if (answerResult.data.length > 0 && answerResult.data.length < 15) {

        } else if (answerResult.data.length === 15) {

        }
      } else if (answerResult.result && answerResult.code === 204) {

      } else {
        // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
      }
    }

    if (step.testStep === 6) {
      if (!step.answer[9].questionsId) {
        toast.warn('10번 질문의 답은 필수 입니다.');
        return
      }
      if (!step.answer[10].questionsId) {
        toast.warn('11번 질문의 답은 필수 입니다.');
        return
      }
      if (!step.answer[11].questionsId) {
        toast.warn('12번 질문의 답은 필수 입니다.');
        return
      }
    }

    if (step.testStep === 7) {
      if (!step.answer[12].questionsId) {
        toast.warn('13번 질문의 답은 필수 입니다.');
        return
      }
      if (!step.answer[13].questionsId) {
        toast.warn('14번 질문의 답은 필수 입니다.');
        return
      }
      if (!step.answer[14].questionsId) {
        toast.warn('15번 질문의 답은 필수 입니다.');
        return
      }
    }

    // if (step.testStep === 8) {
    //   if (!step.answer[16].questionsId) {
    //     toast.warn('16번 질문의 답은 필수 입니다.');
    //     return
    //   }
    //   if (!step.answer[17].questionsId) {
    //     toast.warn('17번 질문의 답은 필수 입니다.');
    //     return
    //   }
    //   if (!step.answer[18].questionsId) {
    //     toast.warn('18번 질문의 답은 필수 입니다.');
    //     return
    //   }
    // }

    console.log(step.testStep)
    if (step.testStep === 1 && step.lang === 'en') {
      step.setTestStep(4)
    } else if (step.testStep === 5 && step.lang === 'en') {
      step.setTestStep(8)
    } else {
      step.setTestStep(step.testStep + 1)
    }
  }

  return (
    <div className="wrapper">
      <div className='test'>
        <form className="multisteps-form__form" action="#" id="wizard" method="POST">
          <div className="form-area position-relative">
            <div className="multisteps-form__panel js-active" data-animation="slideHorz">
              <div className="wizard-forms">
                <div className="pb-100 clearfix">
                  <div className="form-content pera-content">
                    <div className="step-inner-content">
                      <div className="step-progress">
                        <div className="step-progress-bar">
                          <div className="progress">
                            <div className="progress-bar" style={{ width: (step.testStep / 8 * 100) + '%' }}></div>
                          </div>
                        </div>
                      </div>
                      {(step.testStep === 5 && step.lang === 'ko' )&&
                        <div style={{ textAlign: 'center'}}>
                          <p className="step-no">STEP 04</p>
                          <h2>우선순위를 정한다면?</h2>
                          <p>정확한 정보를 알려주셔야 정확한 진단이 가능해요.</p>
                          <div className="notice-wrapper">
                            <div className="notice-content">
                              <h4 className="notice">① 질문에 솔직하게 답변해주세요!</h4>
                              <h4 className="notice">② 가장 나와 가깝다고 생각하는 순서대로 1위부터 4위까지 우선순위를 정해주세요.</h4>
                            </div>
                          </div>
                        </div>
                      }
                      {(step.testStep === 5 && step.lang === 'en' )&&
                        <div style={{ textAlign: 'center'}}>
                          <p className="step-no">STEP 04</p>
                          <h2>What if you set priorities?</h2>
                          <p>An accurate diagnosis is possible only if you provide accurate information.</p>
                          <div className="notice-wrapper">
                            <div className="notice-content">
                              <h4 className="notice">① Please answer the questions honestly!</h4>
                              <h4 className="notice">② Please prioritize from 1st to 4th in the order you think they are closest to you.</h4>
                            </div>
                          </div>
                        </div>
                      }
                      {step.testStep === 6 &&
                        <div style={{ textAlign: 'center'}}>
                          <p className="step-no">STEP 05</p>
                          <h2>하나만 선택한다면?</h2>
                          <p>정확한 정보를 알려주셔야 정확한 진단이 가능해요.</p>
                          <div className="notice-wrapper">
                            <div className="notice-content">
                              <h4 className="notice">평상 시 나와 가장 가까운 [보기]를 선택해주세요!</h4>
                            </div>
                          </div>
                        </div>
                      }
                      {step.testStep === 7 &&
                        <div style={{ textAlign: 'center'}}>
                          <p className="step-no">STEP 06</p>
                          <h2>데이트 할 때 나는?</h2>
                          <p>정확한 정보를 알려주셔야 정확한 진단이 가능해요.</p>
                          <div className="notice-wrapper">
                            <div className="notice-content">
                              <h4 className="notice">내가 가장 선호하는 [보기]를 선택해주세요!</h4>
                            </div>
                          </div>
                        </div>
                      }

                      {step.testStep === 1 && <Step1 key={'step1_1'} />}
                      {step.testStep === 2 && <Step2 key={'step2_1'} options={industryOptions1} selectIndustry={selectIndustry1} />}
                      {step.testStep === 3 && <Step3 key={'step3_1'} options={industryOptions2} selectIndustry={selectIndustry2} />}
                      {step.testStep === 4 && <Step4 key={'step4_1'} />}
                      <>
                        {
                          step.questions && step.questions.map((question, index) => (
                            <React.Fragment key={'q_' + index}>
                              {(step.testStep === 5 && question.type === 'step5') && <Step5 key={'step5_' + index} question={question} index={index} />}
                              {(step.testStep === 6 && question.type === 'step6') && <Step6 key={'step6_' + index} question={question} index={index} />}
                              {(step.testStep === 7 && question.type === 'step7') && <Step6 key={'step7_' + index} question={question} index={index} />}
                              {/* {(step.testStep === 8 && question.type === 'step8') && <Step6 key={'step8_' + index} question={question} index={index} />} */}
                            </React.Fragment>
                          ))
                        }
                      </>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <ul>
                    <li onClick={() => step.setTestStep(step.testStep - 1)}>
                      <span className="home" title="BACK">〈</span>
                    </li>
                    <li onClick={onNext}><span className="js-btn-next" title="NEXT">〉</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
});

export default VersionOne;

