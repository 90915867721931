import { types } from "mobx-state-tree"
import moment from "moment"

export const User = types
  .model("User", {
    id: types.maybe(types.number),
    type: types.maybe(types.string),
    userId: types.maybe(types.string),
    name: types.maybe(types.string),
    email: types.maybe(types.string),
    gender: types.maybe(types.string),
    birthYear: types.maybe(types.number),
    profile: types.maybe(types.string),
    agency: types.maybe(types.string),
    rank: types.maybe(types.string),
    industryId: types.maybe(types.number),
    jobGroupId: types.maybe(types.number),
    agree: types.maybe(types.boolean),
    isLeader: types.maybe(types.boolean),
  })
  .views(self => ({
    get me() {
      return {...self}
    },
  }))
  .actions(self => ({
    async setIndustryId(id) {
      self.industryId = id
    },
    async setJobGroupId(id) {
      self.jobGroupId = id
    },
    async setData(data) {
      Object.keys(data).forEach(d => self[d] = data[d])
    },
    async reset() {
      self.id = 0
      self.type = ''
      self.userId = ''
      self.name = ''
      self.email = ''
      self.gender = ''
      self.birthYear = 0
      self.profile = ''
      self.agency = ''
      self.rank = ''
      self.industryId = -1
      self.jobGroupId = -1
      self.agree = false
      self.isLeader = false
    },
  }))

  export default User