import React, { useState } from "react";
import { observer } from 'mobx-react';
import { useMst } from "../../../models"; 

const Step = observer(() => {
  const { user, step } = useMst();
  const [agreeCheck, setAgreeCheck] = useState(false);

  const updateUser = (data) => {
    user.setData({ ...user, ...data });
  }

  return (
    <>
      <p className="step-no">STEP 01</p>
      <h2>{ step.lang === 'ko' ? '누구신가요?' : 'Who are you?' }</h2>
      <p>{ step.lang === 'ko' ? '정확히 기입 해 주셔야 진단 결과를 받으실 수 있어요.' : 'You must fill out the information accurately to receive the diagnosis results.' }</p>

      <div className="form-inner-area">
        <input type="text" name="name" className="form-control required" minLength="2" placeholder={ step.lang === 'ko' ? '이름 (ex : 홍길동) *' : 'Full Name'} required
          value={user.name}
          onInput={(e) => {updateUser({name: e.currentTarget.value})}}
        />
        <input type="email" name="email" className="form-control required" placeholder={ step.lang === 'ko' ? '이메일 *': 'Email' } required
          value={user.email}
          onInput={(e) => { updateUser({ email: e.currentTarget.value }) }}
        />
        { step.lang === 'ko' &&
        <input type="text" name="birth_year" className="form-control required" placeholder="출생년도 (ex: 1992) *" required
          value={user.birthYear < 1 ? '' : user.birthYear}
          onInput={(e) => { updateUser({ birthYear: parseInt(e.currentTarget.value) }) }}
        />}
      </div>
      <div>
        <div className="row">
          <div className="col-md-6"
            onClick={() => {
              updateUser({ gender: 'M' })
            }}
          >
            <label className={"gender-step-box-content bg-white text-center relative-position " + (user.gender === 'M' ? 'active' : '')} style={{ float: 'right' }}>
              <span className="step-box-text">{step.lang === 'ko' ? '남자' : 'Man'}</span>
              <span className="service-check-option">
                <span>
                  <input
                    type="radio"
                    name="gender"
                    defaultValue="M"
                    defaultChecked
                  />
                </span>
              </span>
            </label>
          </div>
          <div className="col-md-6"
            onClick={() => {
              updateUser({ gender: 'F' })
            }}
          >
            <label className={"gender-step-box-content bg-white text-center relative-position " + (user.gender === 'F' ? 'active' : '')}>
              <span className="step-box-text">{step.lang === 'ko' ? '여자' : 'Woman'}</span> 
              <span className="service-check-option">
                <span>
                  <input
                    type="radio"
                    name="gender"
                    defaultValue="F"
                  />
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div style={{marginTop: 20, fontSize: 15}}>
        <input type="checkbox" theme="material-checkbox" value={agreeCheck} onChange={(e) => { user.setData({ agree: !agreeCheck }); setAgreeCheck(!agreeCheck) }} style={{cursor: 'pointer'}} />&nbsp;
        
        {step.lang === 'en' && <>I agree to the <a href="" style={{color: 'blue', fontWeight: 300}}
          onClick={() => {
            window.open('/agree', 'agree')
          }}
        >Terms of Use and Privacy Policy.</a></>}
        {step.lang === 'ko' && <><a href="" style={{color: 'blue', fontWeight: 300}}
          onClick={() => {
            window.open('/agree', 'agree')
          }}
        >이용약관 및 개인정보처리방침</a>에 동의합니다.</>}
      </div>
    </>
  );
});

export default Step;