import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react';
import { useMst } from "../../../models";
import { Element, scroller, animateScroll } from 'react-scroll';

const Step = observer(({ question }) => {
  const { user, step } = useMst();
  const [answer, setAnswer] = useState(-1)

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 50,
      delay: 0,
      smooth: true,
      offset: 0,
    })
  }, [])
  
  const onUpdate = (option) => {
    setAnswer(option.toJSON())

    const a = {
      type: question.type,
      answerType: question.answerType,
      title: question.title,
      userId: user.id,
      questionsId: question.id,
      answer: [option.toJSON()],
      options: JSON.parse(JSON.stringify(question.options.toJSON())),
    }

    step.setAnswer(question.order - 1, a)
    // console.log(answer)

    scroller.scrollTo('scroll-' + (question.order + 1), {
      duration: 50,
      delay: 0,
      smooth: true,
      offset: -120, 
    })
  }
  
  return (
    <Element name={"scroll-" + question.order} className="budget-area" style={{ marginTop: '50px', opacity: (step.answer && step.answer[question.order - 1].questionsId) ? 0.5 : 1 }}>
      <p>{parseInt(question.order) < 10 ? '0' + question.order : question.order}. {question.question}</p>
      <div className="services-select-option">
        <ul>
          {question.options.map((option, i) => (
            <li key={'select_' + i}
              className={"bg-white" + (parseInt(answer.read) === i + 1 ? ' active' : '')}
              onMouseEnter={(e) => e.currentTarget.classList.add('over')}
              onMouseLeave={(e) => e.currentTarget.classList.remove('over')}
              onClick={(e) => {
                onUpdate(option)
              }}
            ><label className="option-text">{option.text}<input type="radio" name="web_service" value={option.text} /></label></li>
          )) }
        </ul>
      </div>
    </Element>
  );
});

export default Step;