
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../assets/img/code.jpg';
import { useMst } from "../models";

const Code = () => {
  const [codeResult, setCodeResult] = useState();
  const { user, step } = useMst();
  const navigate = useNavigate();

  useEffect(() => {
    if (!step.testStep || step.testStep < 1) navigate('/');
    if (step.testStep > step.openStep || !step.openStep) step.setOpenStep(step.testStep);

    setTimeout(() => navigate('/report'), 3000)
  }, [])

  return (
    <div className="wrapper" style={{ backgroundImage: `url(${background})`, height: '100vh' }}>
      <div className="loading">
        <div className="code-title">
          <div className="thank-txt text-center thank-icon">
            <div>
              <img src={require("../assets/img/spaceman.png")} alt="" width="300px" />
            </div>
            <div>
              <img src={require("../assets/img/loading.gif")} alt="" width="200px" />
            </div>
            <h4>{step.lang === 'ko' ? '결과를 분석 중입니다.' : 'The results are being analyzed.'}</h4>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Code;